import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./HandshakeWelcome.css";
import chukwuma from "../../images/fs/5.0/chukwuma.jpg";
import kenechukwu from "../../images/fs/5.0/kenechukwu.png";
import anayor from "../../images/fs/5.0/anayor.jpg";
import barnabas from "../../images/fs/5.0/banabas.jpg";
import henrietta from "../../images/fs/5.0/henrietta.jpg";
import mayowa from "../../images/fs/5.0/mayowa.jpg";
import moderator from "../../images/naomi.png";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { IoCalendar } from "react-icons/io5";
import { IoMdClock } from "react-icons/io";

const HandshakeWelcome = () => {
  const popover = (name) => {
    return (
      <Popover body={true} id="popover-basic">
        {name}
      </Popover>
    );
  };

  const [key, setKey] = useState("allDays");

  const allDaysContent = (
    <div className="col-md-12 justify-content-center hw d-sm-flex text-black-50">
      <div className="firstDiv card text-black-50 text-center d-none d-sm-block">
        <div className="card-header">Time(WAT)</div>
        {/* <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div> */}
        <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div>
      </div>
      <div className="firstDiv card text-black-50 text-sm-center">
        <div className="card-header d-none d-sm-block">
          Monday
          <br className="d-none d-sm-block" /> 16th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <h6 className="text-uppercase event-title">
            Creating <br className="d-none d-sm-block" /> Opportunities
          </h6>
          <div className="text-black cb-fs-16 pt-md-3 mt-md-1">
            Awakening A Mindset of <br className="d-none d-sm-block" />{" "}
            Possibilities
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 16th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 mt-md-4 pt-md-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Chukwuma Ezeh")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={chukwuma}
                alt="chukwuma eze"
              />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Kenechukwu Ogbuagu")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={kenechukwu}
                alt="kenechukwu ogbuagu"
              />
            </OverlayTrigger>
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Naomi Lucas")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={moderator}
                alt="naomi lucas"
              />
            </OverlayTrigger>
          </div>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <h6 className="text-uppercase event-title">
            Decent Jobs
            <br className="d-none d-sm-block" />
          </h6>
          <div className="text-black cb-fs-16">
            Tackling Unemployment
            <br className="d-none d-sm-block" /> At Scale
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 2nd December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Christiano Ronaldo")}
              key="top"
              placement="top"
            >
              <Image className="panelist-img me-2" src={ronaldo} alt="Image" />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Sergio Ramos")}
              key="top"
              placement="top"
            >
              <img className="panelist-img" src={ramos} />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Selena Gomez")}
              key="top"
              placement="top"
            >
              <img className="panelist-img ms-3" src={serene} />
            </OverlayTrigger>
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <img className="panelist-img" src={moderator} />
          </div>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center">
        <div className="card-header d-none d-sm-block">
          Tuesday
          <br className="d-none d-sm-block" /> 17th December2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol">
          <h6 className="text-uppercase event-title">
            Founders and <br className="d-none d-sm-block" /> Intrapreneurs
            Panel
          </h6>
          <div className="text-black cb-fs-16">
            Perspectives from
            <br className="d-none d-sm-block" /> Employers of Labour
            <br className="d-none d-sm-block" /> and Those Who Work
            <br className="d-none d-sm-block" /> With Them
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 17th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Anayor Daniel Onuoha")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={anayor}
                alt="Anayor Daniel Onuoha"
              />
            </OverlayTrigger>
            {/* <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Gloria Okorie")}
              key="top"
              placement="top"
            >
              <img className="panelist-img" src={gloria} alt="gloria okorie" />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Gbenga Ajetomobi")}
              key="top"
              placement="top"
            >
              <img className="panelist-img ms-3" src={gbenga} alt="Gbenga Ajetomobi" />
            </OverlayTrigger> */}
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Naomi Lucas")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={moderator}
                alt="naomi lucas"
              />
            </OverlayTrigger>
          </div>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol">
          <h6 className="text-uppercase event-title">
            Intrapreneurship <br className="d-none d-sm-block" />
          </h6>
          <div className="text-black cb-fs-16">
            Spotlighting Noteworthy <br className="d-none d-sm-block" /> Models
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 3rd December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Christiano Ronaldo")}
              key="top"
              placement="top"
            >
              <Image className="panelist-img me-2" src={ronaldo} alt="Image" />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Sergio Ramos")}
              key="top"
              placement="top"
            >
              <img className="panelist-img" src={ramos} />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Selena Gomez")}
              key="top"
              placement="top"
            >
              <img className="panelist-img ms-3" src={serene} />
            </OverlayTrigger>
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <img className="panelist-img" src={moderator} />
          </div>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center">
        <div className="card-header d-none d-sm-block">
          Wednesday
          <br className="d-none d-sm-block" /> 18th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <h6 className="text-uppercase event-title">
            Switching Careers <br className="d-none d-sm-block" />
          </h6>
          <div className="text-black cb-fs-16 pt-md-3 mt-md-1">
            Insights To Help You <br className="d-none d-sm-block" /> Transition
            Successfully
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 18th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 mt-md-4 pt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Barnabas Usman")}
              key="top"
              placement="top"
            >
              <Image className="panelist-img me-2" src={barnabas} alt="Image" />
            </OverlayTrigger>
            {/* <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Gabriel Balogun")}
              key="top"
              placement="top"
            >
              <img className="panelist-img" src={gabriel} />
            </OverlayTrigger> */}
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Naomi Lucas")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={moderator}
                alt="naomi lucas"
              />
            </OverlayTrigger>
          </div>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue d-none d-sm-block">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center">
        <div className="card-header d-none d-sm-block">
          Thursday
          <br className="d-none d-sm-block" /> 19th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol">
          <h6 className="text-uppercase event-title">
            Thriving Abroad <br className="d-none d-sm-block" />
          </h6>
          <div className="text-black cb-fs-16 pt-md-3 mt-md-4">
            Strategies for Enduring <br className="d-none d-sm-block" /> Success
            in the Diaspora
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 19th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Mayowa Adegoke")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={mayowa}
                alt="mayowa adegoke"
              />
            </OverlayTrigger>
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Naomi Lucas")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={moderator}
                alt="Naomi Lucas"
              />
            </OverlayTrigger>
          </div>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol">
          <h6 className="text-uppercase event-title">
            Remote Work <br className="d-none d-sm-block" />
          </h6>
          <div className="text-black cb-fs-16">
            Practical Strategies for <br className="d-none d-sm-block" />Securing Remote
            <br className="d-none d-sm-block" />
            Work Opportunities
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 5th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Ibukun Amosu")}
              key="top"
              placement="top"
            >
              <Image className="panelist-img me-2" src={ibukun} alt="Image" />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Mary-Esther Anele")}
              key="top"
              placement="top"
            >
              <img className="panelist-img" src={maryesther} />
            </OverlayTrigger>
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <img className="panelist-img" src={moderator} />
          </div>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center">
        <div className="card-header d-none d-sm-block">
          Friday
          <br className="d-none d-sm-block" /> 20th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol">
          <h6 className="text-uppercase event-title">
            Remote Work <br className="d-none d-sm-block" />
          </h6>
          <div className="text-black cb-fs-16 pt-md-3 mt-md-4">
            Practical Strategies <br className="d-none d-sm-block" /> for
            Securing
            <br className="d-none d-sm-block" /> Remote Work
            <br className="d-none d-sm-block" /> Opportunities
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 20th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Henrietta Ogu")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={henrietta}
                alt="Henrietta Ogu"
              />
            </OverlayTrigger>
            {/* <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Mary-Esther Anele")}
              key="top"
              placement="top"
            >
              <img className="panelist-img" src={maryesther} />
            </OverlayTrigger> */}
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Naomi Lucas")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={moderator}
                alt="nomi lucas"
              />
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  );

  const day1Content = (
    <div className="col-md-12 justify-content-center hw d-sm-flex text-black-50">
      <div className="firstDiv card text-black-50 text-center d-none d-sm-block">
        <div className="card-header">Time(WAT)</div>
        {/* <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div> */}
        <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div>
      </div>
      <div className="firstDiv card text-black-50 text-sm-center">
        <div className="card-header d-none d-sm-block">
          Monday
          <br className="d-none d-sm-block" /> 16th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <h6 className="text-uppercase event-title">
            Creating <br className="d-none d-sm-block" /> Opportunities
          </h6>
          <div className="text-black cb-fs-16">
            Awakening A Mindset of <br className="d-none d-sm-block" />{" "}
            Possibilities
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 16th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Chukwuma Ezeh")}
              key="top"
              placement="top"
            >
              <Image className="panelist-img me-2" src={chukwuma} alt="Image" />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Kenechukwu Ogbuagu")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={kenechukwu}
                alt="Image"
              />
            </OverlayTrigger>
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Naomi Lucas")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={moderator}
                alt="naomi lucas"
              />
            </OverlayTrigger>
          </div>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <h6 className="text-uppercase event-title">
            Creating <br className="d-none d-sm-block" /> Opportunities
          </h6>
          <div className="text-black cb-fs-16">
            Awakening a mindset of <br className="d-none d-sm-block" />{" "}
            possibilities
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 3rd December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Elsa Bankole")}
              key="top"
              placement="top"
            >
              <Image className="panelist-img me-2" src={elsa} alt="Image" />
            </OverlayTrigger>
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <img className="panelist-img" src={moderator} />
          </div>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Tuesday
          <br className="d-none d-sm-block" /> 17th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Wednesday
          <br className="d-none d-sm-block" /> 17th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue align-items-center">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Thursday
          <br className="d-none d-sm-block" /> 19th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Friday
          <br className="d-none d-sm-block" /> 19th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
    </div>
  );

  const day2Content = (
    <div className="col-md-12 justify-content-center hw d-sm-flex text-black-50">
      <div className="firstDiv card text-black-50 text-center d-none d-sm-block">
        <div className="card-header">Time(WAT)</div>
        {/* <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div> */}
        <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div>
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Monday
          <br className="d-none d-sm-block" /> 16th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center">
        <div className="card-header d-none d-sm-block">
          Tuesday
          <br className="d-none d-sm-block" /> 17th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol">
          <h6 className="text-uppercase event-title">
            Founders and <br className="d-none d-sm-block" />
            Intrapreneurship Panel
          </h6>
          <div className="text-black cb-fs-16">
            Perspectives from <br className="d-none d-sm-block" /> Employers of
            Labour <br className="d-none d-sm-block" /> and Those Who Work{" "}
            <br className="d-none d-sm-block" /> With Them
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 17th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Anayor Daniel Onuoha")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={anayor}
                alt="Anayor Daniel Onuoha"
              />
            </OverlayTrigger>
            {/* <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Gloria Okorie")}
              key="top"
              placement="top"
            >
              <img className="panelist-img" src={gloria} alt="gloria okorie" />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Gbenga Ajetomobi")}
              key="top"
              placement="top"
            >
              <img className="panelist-img ms-3" src={gbenga} alt="Gbenga Ajetomobi" />
            </OverlayTrigger> */}
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Naomi Lucas")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={moderator}
                alt="naomi lucas"
              />
            </OverlayTrigger>
          </div>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol">
          <h6 className="text-uppercase event-title">
            Intrapreneurship <br className="d-none d-sm-block" />
          </h6>
          <div className="text-black cb-fs-16">
            Spotlighting Noteworthy <br className="d-none d-sm-block" /> Models
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 9th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Christiano Ronaldo")}
              key="top"
              placement="top"
            >
              <Image className="panelist-img me-2" src={ronaldo} alt="Image" />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Sergio Ramos")}
              key="top"
              placement="top"
            >
              <img className="panelist-img" src={ramos} />
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Selena Gomez")}
              key="top"
              placement="top"
            >
              <img className="panelist-img ms-3" src={serene} />
            </OverlayTrigger>
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <img className="panelist-img" src={moderator} />
          </div>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Wednesday
          <br className="d-none d-sm-block" /> 18th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue align-items-center">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Thurday
          <br className="d-none d-sm-block" /> 19th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Friday
          <br className="d-none d-sm-block" /> 20th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
    </div>
  );

  const day3Content = (
    <div className="col-md-12 justify-content-center hw d-sm-flex text-black-50">
      <div className="firstDiv card text-black-50 text-center d-none d-sm-block">
        <div className="card-header">Time(WAT)</div>
        {/* <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div> */}
        <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div>
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Monday
          <br className="d-none d-sm-block" /> 16th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Tuesday
          <br className="d-none d-sm-block" /> 17th December 2024
        </div>
        <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center">
        <div className="card-header d-none d-sm-block">
          Wednesday
          <br className="d-none d-sm-block" /> 18th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <h6 className="text-uppercase event-title">
            Switching Careers <br className="d-none d-sm-block" />
          </h6>
          <div className="text-black cb-fs-16">
            Insights To Help You <br className="d-none d-sm-block" /> Transition
            Successfully
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 18th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Barnabas Usman")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={barnabas}
                alt="Barnabas Usman"
              />
            </OverlayTrigger>
            {/* <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Gabriel Balogun")}
              key="top"
              placement="top"
            >
              <img className="panelist-img" src={gabriel} />
            </OverlayTrigger> */}
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Naomi Lucas")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={moderator}
                alt="naomi lucas"
              />
            </OverlayTrigger>
          </div>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue d-none d-sm-block">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Thurday
          <br className="d-none d-sm-block" /> 19th December 2024
        </div>
        <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Friday
          <br className="d-none d-sm-block" /> 20th December 2024
        </div>
        <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
    </div>
  );

  const day4Content = (
    <div className="col-md-12 justify-content-center hw d-sm-flex text-black-50">
      <div className="firstDiv card text-black-50 text-center d-none d-sm-block">
        <div className="card-header">Time(WAT)</div>
        {/* <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div> */}
        <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div>
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Monday
          <br className="d-none d-sm-block" /> 16th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Tuesday
          <br className="d-none d-sm-block" /> 17th December 2024
        </div>
        <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Wednesday
          <br className="d-none d-sm-block" /> 18th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue align-items-center">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center">
        <div className="card-header d-none d-sm-block">
          Thursday
          <br className="d-none d-sm-block" /> 19th December 2024
        </div>
        <div className="card-body flex-column timeCol">
          <h6 className="text-uppercase event-title">
            Thriving Abroad <br className="d-none d-sm-block" />
          </h6>
          <div className="text-black cb-fs-16">
            Strategies For Enduring <br className="d-none d-sm-block" /> Success
            in the Diaspora
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 19th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Mayowa Adegoke")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={mayowa}
                alt="Mayowa Adegoke"
              />
            </OverlayTrigger>
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Naomi Lucas")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={moderator}
                alt="naomi lucas"
              />
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Friday
          <br className="d-none d-sm-block" /> 20th December 2024
        </div>
        <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
    </div>
  );

  const day5Content = (
    <div className="col-md-12 justify-content-center hw d-sm-flex text-black-50">
      <div className="firstDiv card text-black-50 text-center d-none d-sm-block">
        <div className="card-header">Time(WAT)</div>
        {/* <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div> */}
        <div className="card-body timeCol">
          <div className="time">5:00PM - 7:00PM</div>
        </div>
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Monday
          <br className="d-none d-sm-block" /> 16th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Tuesday
          <br className="d-none d-sm-block" /> 17th December2024
        </div>
        <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column timeCol">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Wednesday
          <br className="d-none d-sm-block" /> 18th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue align-items-center">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center d-none d-sm-block">
        <div className="card-header d-none d-sm-block">
          Thursday
          <br className="d-none d-sm-block" /> 19th December 2024
        </div>
        <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div>
        {/* <div className="card-body flex-column cb-border-bottom timeCol bg-dark-blue align-items-center">
          <span
            className="text-black d-flex align-items-center mx-5"
            style={{ height: "100%" }}
          >
            No event
          </span>
        </div> */}
      </div>
      <div className="firstDiv card text-black-50 text-sm-center">
        <div className="card-header d-none d-sm-block">
          Friday
          <br className="d-none d-sm-block" /> 20th December 2024
        </div>
        <div className="card-body flex-column timeCol">
          <h6 className="text-uppercase event-title">
            Remote Work <br className="d-none d-sm-block" />
          </h6>
          <div className="text-black cb-fs-16">
            Practical Strategies <br className="d-none d-sm-block" /> for
            Securing
            <br className="d-none d-sm-block" /> Remote Work
            <br className="d-none d-sm-block" /> Opportunities
          </div>
          <div className="cb-datetime d-block d-sm-none mt-4">
            {" "}
            <IoCalendar /> 20th December 2024{" "}
          </div>
          <div className="cb-datetime d-block d-sm-none mt-3 mb-4">
            {" "}
            <IoMdClock /> 5:00PM - 7:00PM{" "}
          </div>
          <div className="panelist mt-3 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              panelist:
            </h6>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Henrietta Ogu")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={henrietta}
                alt="Henrietta Ogu"
              />
            </OverlayTrigger>
            {/* <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Mary-Esther Anele")}
              key="top"
              placement="top"
            >
              <img className="panelist-img" src={maryesther} />
            </OverlayTrigger> */}
          </div>
          <div className="moderator mt-4 d-flex d-sm-block align-items-center">
            <h6 className="text-uppercase text-black mb-2 pe-4 mt-2 mt-sm-0 pe-sm-0 cb-fs-18">
              moderator:
            </h6>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={popover("Naomi Lucas")}
              key="top"
              placement="top"
            >
              <Image
                className="panelist-img me-2"
                src={moderator}
                alt="naomi lucas"
              />
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  );

  const days = [
    {
      key: "allDays",
      label: "All Days",
      dayName: "All Days",
      date: "",
      events: [allDaysContent],
    },
    {
      key: "day1",
      label: "Day 1",
      dayName: "Monday",
      date: "2th December 2024",
      events: [day1Content],
    },
    {
      key: "day2",
      label: "Day 2",
      dayName: "Tuesday",
      date: "17th December 2024",
      events: [day2Content],
    },
    {
      key: "day3",
      label: "Day 3",
      dayName: "Wednesday",
      date: "18th December 2024",
      events: [day3Content],
    },
    {
      key: "day4",
      label: "Day 4",
      dayName: "Thursday",
      date: "19th December 2024",
      events: [day4Content],
    },
    {
      key: "day5",
      label: "Day 4",
      dayName: "Friday",
      date: "20th December 2024",
      events: [day5Content],
    },
  ];

  return (
    <section className="handshake-welcome pb-3 container-fluid">
      <div className="row mt-5 justify-content-center p-sm-0 p-3">
        <span className="text-center text-uppercase text-nlco-secondary">
          Welcome to handshake real world perspectives
        </span>
        <div className="col-md-10 text-center text-black mt-5 fw-bold">
          A line up of 5-10 virtual events taking place over the course of a
          week. Welcome To Handshake! Real World Perspectives brings together
          notable, seasoned experts and Business leaders to share first-hand
          views on critical topics.
        </div>
        <div className="col-md-10 text-center text-black mt-5 fw-bold">
          Curated and moderated by Naomi Lucas and her team, Handshake! Real
          World Perspectives is the premier gathering for young African
          professionals to get valuable insights, actionable strategies and to
          build their network.
        </div>
      </div>

      <div className="row justify-content-center" style={{ marginTop: "7rem" }}>
        <span className="text-center text-uppercase text-nlco-secondary">
          Event Details
        </span>
        <div className="col-md-6 col-12 text-uppercase text-black days-container mt-5">
          <div className="row justify-content-sm-center">
            <div
              className={`col-4 col-sm-2 py-2 my-2 day-div text-center ${
                key === "allDays" ? "active" : ""
              }`}
              onClick={() => setKey("allDays")}
            >
              All Days
            </div>
            <div
              className={`col-4 col-sm-2 py-2 my-2 day-div text-center ${
                key === "day1" ? "active" : ""
              }`}
              onClick={() => setKey("day1")}
            >
              Day 1
            </div>
            <div
              className={`col-4 col-sm-2 py-2 my-2 day-div text-center ${
                key === "day2" ? "active" : ""
              }`}
              onClick={() => setKey("day2")}
            >
              Day 2
            </div>
            <div
              className={`col-4 col-sm-2 py-2 my-2 day-div text-center ${
                key === "day3" ? "active" : ""
              }`}
              onClick={() => setKey("day3")}
            >
              Day 3
            </div>
            <div
              className={`col-4 col-sm-2 py-2 my-2 day-div text-center ${
                key === "day4" ? "active" : ""
              }`}
              onClick={() => setKey("day4")}
            >
              Day 4
            </div>
            <div
              className={`col-4 col-sm-2 py-2 my-2 day-div text-center ${
                key === "day5" ? "active" : ""
              }`}
              onClick={() => setKey("day5")}
            >
              Day 5
            </div>
          </div>
        </div>
      </div>

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="row justify-content-center mb-5"
      >
        {days.map((day) => (
          <Tab key={day.key} eventKey={day.key}>
            {day.events.map((event, index) => (
              <React.Fragment key={index}>{event}</React.Fragment>
            ))}
          </Tab>
        ))}
      </Tabs>
    </section>
  );
};

export default HandshakeWelcome;
