import React, { useState, useEffect } from "react";
import "./Hero.css";
import Navbar from "../Navbar/Navbar";
import { IoArrowUp, IoCalendar, IoMic, IoPeople } from "react-icons/io5";
import { Link } from "react-router-dom";

// Speaker images
import kenechukwu from "../../images/fs/5.0/kenechukwu.png";
import chukwuma from "../../images/fs/5.0/chukwuma.jpg";
import banabas from "../../images/fs/5.0/banabas.jpg";
import anayor from "../../images/fs/5.0/anayor.jpg";
import henrietta from "../../images/fs/5.0/henrietta.jpg";
import mayowa from "../../images/fs/5.0/mayowa.jpg";

const Hero = () => {
  const [isScrolled, setIsScrolled] = useState(window.scrollY > 0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showMenu, setShowMenu] = useState(false);
  const [displayText, setDisplayText] = useState({});

  const textData = [
    {
      leadText: "Creating Opportunities:",
      secondaryText: "Awakening a Mindset of Possibilities",
      dateTime: "Monday 16th December, 2024",
      speaker: chukwuma,
      speakerName: "Chukwuma Ezeh",
      speakerJob: "CEO, Happivibe Services/Co-Founder Gemdeconq",
    },
    {
      leadText: "Creating Opportunities:",
      secondaryText: "Awakening a Mindset of Possibilities",
      dateTime: "Monday 16th December, 2024",
      speaker: kenechukwu,
      speakerName: "Kenechukwu Ogbuagu",
      speakerJob: "Founder, Nibcard Games",
    },
    {
      leadText: "Founders and Intrapreneurs Panel",
      secondaryText:
        "Perspectives from Employers of Labour And Those Who Work With Them",
      dateTime: "Tuesday 17th December, 2024",
      speaker: anayor,
      speakerName: "Anayor Daniel Onuoha",
      speakerJob: "Talent Management Specialist",
    },
    {
      leadText: "Switching Careers:",
      secondaryText: "Insights To Help You Transition Successfully",
      dateTime: "Wednesday 4th December, 2024",
      speaker: banabas,
      speakerName: "Barnabas Usman",
      speakerJob:
        "Government Relations & Partnership Lead at One Acre Fund, Nigeria.",
    },
    {
      leadText: "Thriving Abroad",
      secondaryText: "Strategies For Enduring Success In The Diaspora",
      dateTime: "Thursday 5th December, 2024",
      speaker: mayowa,
      speakerName: "Mayowa Adegoke",
      speakerJob: "Founder, Road To Success Seminar",
    },
    {
      leadText: "Remote Work",
      secondaryText:
        "Practical Strategies For Securing Remote Work Opportunities",
      dateTime: "Friday 6th December, 2024",
      speaker: henrietta,
      speakerName: "Henrietta Ogu ",
      speakerJob: "Program Manager, Opportunik Global Fund",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setShowMenu(false);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    let currentIndex = 0;
    const textInterval = setInterval(() => {
      currentIndex = (currentIndex + 1) % textData.length;
      setDisplayText(textData[currentIndex]);
    }, 3000); // Change text every 3000 milliseconds (3 seconds)

    // Initialize first display text
    setDisplayText(textData[0]);

    return () => {
      clearInterval(textInterval);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [textData.length]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="hero-section pb-lg-5">
      <Navbar
        toggleMenu={toggleMenu}
        isMobile={isMobile}
        isScrolled={isScrolled}
        showMenu={showMenu}
      />
      <div
        className={`container ${
          isScrolled ? "h-80 h-40" : "h-75 h-40"
        } d-flex align-items-center`}
      >
        <div
          style={{ opacity: "1" }}
          className="row align-items-center justify-content-between"
        >
          <div className="col-lg-8 col-md-7 col-sm-12 text-container current-div">
            <span className="leadText lead-text-mobile mt-6">
              {displayText.leadText}
            </span>
            <div className="secondaryText">{displayText.secondaryText}</div>
            <p className="dateTime date-time-mobile">{displayText.dateTime}</p>
          </div>

          <div
            style={{
              position: "absolute",
              opacity: "0",
              transition: "opacity 10s linear",
            }}
            className="col-lg-8 col-md-7 col-sm-12 text-container next-div"
          >
            {/* Placeholder for next text - currently not dynamically used */}
          </div>

          <div className="col-lg-4 col-md-5 col-sm-12 position-relative">
            <img
              className="rounded rounded-8 w-75 d-none d-md-block"
              src={displayText.speaker}
              alt={`Speaker: ${displayText.speakerName}`}
            />
            <h2 className="speakerName pt-4">{displayText.speakerName}</h2>
            <p style={{ color: "#1d1e4a" }}>{displayText.speakerJob}</p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 btn-speaker-div">
            <Link to={"/speaker-application"}>
              <button className="btn-apply w-100" type="button">
                <IoArrowUp className="rotate-90" /> Apply to speak
              </button>
            </Link>
          </div>
          <div className="col-md-4 btn-partner-div p-0">
            <Link to={"/partner"}>
              <button className="btn-partner w-100" type="button">
                <IoArrowUp className="rotate-90" /> Partner with us
              </button>
            </Link>
          </div>
          <div className="col-md-4 btn-register-div">
            <Link to={"/register"}>
              <button className="btn-register w-100" type="button">
                <IoArrowUp className="rotate-90" /> Register
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3">
        <div className="row d-sm-flex align-items-center">
          <div
            className="col-md-3 col-6 text-center border-right fw-bolder"
            style={{ color: "#1d1e4a" }}
          >
            <IoCalendar /> 5 Days
          </div>
          <div
            className="col-md-3 col-6 text-center border-right fw-bolder"
            style={{ color: "#1d1e4a" }}
          >
            <IoCalendar /> 5 Events
          </div>
          <div
            className="col-md-3 col-6 text-center border-right fw-bolder"
            style={{ color: "#1d1e4a" }}
          >
            <IoMic /> 6 Speakers
          </div>
          <div
            className="col-md-3 col-6 text-center fw-bolder"
            style={{ color: "#1d1e4a" }}
          >
            <IoPeople /> 2,000 Attendees
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
